<template>
  <router-view></router-view>
  <h1>Master Fee</h1>
  <hr/>
  <bs-error-message :errors="errorMessages"/>

  <div class="row justify-content-between">
    <div class="col-md-6">
      <div class="d-grid gap-3 d-md-block">
        <router-link to="/fee/create" class="btn btn-primary btn-sm me-md-1">
          <i class="bi bi-file-earmark"></i> Create
        </router-link>

      </div>
    </div>
    <div class="col-md-6">
      <div class="pagination-right">
        <bs-pagination :pageNumber="pageNumber" :perPage="perPage" :totalPage="totalPage" :totalRow="totalRow"
                       @toPage="toPage"/>
      </div>
    </div>
  </div>

  <div class="">
    <table class="table table-striped">
      <thead>
          <tr>
              <th rowspan="2">
                  Action
              </th>
              <th>
                  <bs-ordering property="feeName" @reorder="reorder">Fee Name</bs-ordering>
              </th>
              <th>
                  <bs-ordering property="feeProfileName" @reorder="reorder">Fee Profile Name</bs-ordering>
              </th>
              <th>
                  <bs-ordering property="remarks" @reorder="reorder">Remarks</bs-ordering>
              </th>
              <th>
                  <bs-ordering property="quantity" @reorder="reorder">Quantity</bs-ordering>
              </th>
              <th>
                  <bs-ordering property="status" @reorder="reorder">Status</bs-ordering>
              </th>
              <th>
                  <bs-ordering property="validStart" @reorder="reorder">Valid Start</bs-ordering>
              </th>
              <th>
                  <bs-ordering property="validEnd" @reorder="reorder">Valid End</bs-ordering>
              </th>
          </tr>
          <tr>
            <th>
              <div>
                <select v-model="filter.feeIdEquals" class="form-control" :disabled="id && item.id != 0" @update:model-value="search()">
                  <option :value="list.id" v-for="list in feeList" :key="'feeoption-' + list.id">{{ list.name }}</option>
                </select>
              </div>
            </th>
            <th>
              <div>
                <bs-textbox placeholder="Nama" v-model="filter.ProfileNameContain" @keyup="search()"/>
              </div>
            </th>
            <th>
              <div>
                <bs-textbox placeholder="Remarks" v-model="filter.RemarksContains" @keyup="search()"/>
              </div>
            </th>
            <th>
              <div>
                <bs-textbox type="number" placeholder="Quantity" v-model="filter.QuantityEquals" @keyup="search()"/>
              </div>
            </th>
            <th>
              <div>
                <select v-model="filter.statusEquals" class="form-control" :disabled="id && item.id != 0" @update:model-value="search()">
                  <option :value="list.id" v-for="list in statusList" :key="'feeoption-' + list.id">{{ list.name }}</option>
                </select>
              </div>
            </th>
            <th>
              <div>
                <bs-date-picker v-model="filter.ValidStartFrom" placeholder="Valid Start From" @change="search()" />
                <bs-date-picker class="mt-1" v-model="filter.ValidStartUntil" placeholder="Valid Start To" @change="search()" />
              </div>
            </th>
            <th>
              <div>
                <bs-date-picker v-model="filter.ValidEndFrom" placeholder="Valid End From" @change="search()" />
                <bs-date-picker class="mt-1" v-model="filter.ValidEndUntil" placeholder="Valid End Until" @change="search()" />
              </div>
            </th>
          </tr>
      </thead>
      <tbody>
      <template v-if="data != null && data.length > 0">
        <tr v-for="item in data" :key="item.id">
          <td>
            <div class="btn-group">
              <button class="btn btn-sm btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="bi bi-menu-down"></i> Action
              </button>
              <ul class="dropdown-menu">
                <li>
                  <button @click="detailItem(item)" class="dropdown-item"><i class="bi bi-eye"></i> View Details
                  </button>
                </li>
                <li>
                  <button @click="editItem(item)" class="dropdown-item"><i class="bi bi-pencil-square"></i> Edit
                  </button>
                </li>
                <li>
                  <button @click="deleteItem(item)" class="dropdown-item"><i class="bi bi-trash"></i> Delete</button>
                </li>
              </ul>
            </div>
          </td>
          <td>
            {{ feeList[item.feeId-1].name }}
          </td>
          <td>
            {{ item.feeProfileNama }}
          </td>
          <td>
            {{ item.remarks }}
          </td>
          <td>
            {{ item.quantity }}
          </td>
          <td>
            {{ statusList[item.status].name }}
          </td>
          <td>
            <view-date v-model="item.validStart"/>
          </td>
          <td>
            <view-date v-model="item.validEnd"/>
          </td>
        </tr>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>
// import FeeProfileService from "@/services/fee-profile-service.js";
import FeeService from "@/services/fee-service.js";

export default {
  data: function () {
    return {
      filter: {
        page: 1,
        perPage: 10,
        orderByProperty: 'id',
        orderType: 0
      },
      errorMessages: null,
      pageNumber: 1,
      perPage: 10,
      totalPage: 100,
      totalRow: 0,
      data: Array(),
      feeList: [
				{
					id: 1,
					name: "Government Fee",
				},
				{
					id: 2,
					name: "Throughput Fee",
				},
				{
					id: 3,
					name: "Flowage Fee",
				},
        {
          id: null,
          name: "All Fee",
        },
			],
			statusList: [
        {
					id: 0,
					name: "Not OK",
				},
				{
					id: 1,
					name: "OK",
				},
				{
					id: 2,
					name: "OK-Revised",
				},
			],
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async toPage(pageNumber) {
      this.filter.page = pageNumber;
      this.loadData();
    },
    async reorder(property, orderType) {
      this.filter.orderByProperty = property;
      this.filter.orderType = orderType;
      this.loadData();
    },
    async search() {
      this.filter.page = 1;
      this.loadData();
    },
    async loadData() {
      if(this.filter.StatusEquals){
        this.filter.StatusEquals -= 1;
      }
      const result = await FeeService.getList(this.filter);
      if (result.status) {
        this.errorMessages = null;
      } else {
        this.errorMessages = result.errorMessages;
      }
      const data = result.data;
      this.pageNumber = data.pageNumber;
      this.perPage = data.perPage;
      this.totalPage = data.totalPage;
      this.totalRow = data.totalRow;
      this.data = data.data;
    },
    detailItem(item) {
      this.$router.push(`/fee/detail/${item.id}`);
    },
    editItem(item) {
      this.$router.push(`/fee/edit/${item.id}`);
    },
    async deleteItem(item) {
      if (confirm(`Are you sure want to delete all Fees for the Fee Profile "${item.feeProfileNama}"`)) {
        const result = await FeeService.delete(item.id);
        if (result.status) {
          this.loadData();
        }
      }
    }
  },
  watch: {
    $route: function () {
      if (this.$route.path == "/fee-profile") {
        this.loadData();
      }
    }
  },
}
</script>

<style>

</style>